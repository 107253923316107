import React from 'react'
import { enquireScreen } from 'enquire-js'
import { graphql } from 'gatsby'
// import Markdown from 'react-markdown'

import Layout from '../components/layout'

import '../components/antd/less/antMotionStyle.less'
import SEO from '../components/seo'

let isMobile
enquireScreen(b => {
  isMobile = b
})

export default class Solution extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
  }

  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { solution } = strapi
    return (
      <Layout curIndex={3} hasBg={true}>
        <SEO title='解决方案' />
        <div className='solution-detail main-con'>
          <h1 style={{ textAlign: 'center' }}>{solution.title}</h1>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: ID!) {
    strapi {
      solution(id: $id) {
        id
        title
        content
        image {
          url
        }
        image_link
      }
    }
  }
`
